<template lang="html">
	<a-pagination v-model:current="currSelf" v-model:pageSize="rowSelf" :total="count" show-size-changer :show-total="count => `共 ${count} 条`" show-less-items @change="handleCurrChange" @showSizeChange="handleSizeChange" />
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
    name: 'TablePagination',
    props: {
        curr: {
            required: true
        },
        row: {
            required: true
        },
        count: {
            required: true
        }
    },
    setup(props, ctx) {
        let param = reactive({
            currSelf: props.curr,
            rowSelf: props.row,
        })
        const handleCurrChange = pageNumber => {
            ctx.emit('changeTableRequest', 'curr', pageNumber)
        };
        const handleSizeChange = (current, pageSize) => {
            ctx.emit('changeTableRequest', 'row', pageSize)
        };
        return {
            ...toRefs(param),
            handleCurrChange,
            handleSizeChange,
        }
    }
})
</script>
