<!--学生管理_——视频浏览-->
<template>
    <div>
        <a-page-header :ghost="false" :title="title" @back="() => $router.go(-1)" class="pageHeader">
            <template #extra>
                <div style="marginLeft:auto">
                    <a-input-search v-model:value="param.name" placeholder="输入视频名称" style="width: 320px;" @search="filterList" />
                </div>
            </template>
        </a-page-header>

        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #cover="{text, record, index}">
                    <a-image :width="60" :src="domain+text" :fallback="domain+'/default.png'" style="border-radius:4px" />
                </template>
                <template #progress_percet="{text, record, index}">
                    <a-progress :percent="text" strokeColor="#189490" status="active" />
                </template>
                <template #operation="{ record }">
                    <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import { useRoute } from 'vue-router'
export default defineComponent({
    components: {
        TablePagination,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        onMounted(() => {
            if (route.query.id) getList();//学生浏览视频列表
        });
        const initData = reactive({
            list: [],//浏览视频列表
            columns: [
                { title: 'ID', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '视频封面', dataIndex: 'video.cover', slots: { customRender: 'cover' },width:150 },
                { title: '视频名称', dataIndex: 'video.name' },
                { title: '视频类型', dataIndex: 'video.cate.name' },
                { title: '浏览进度', dataIndex: 'progress_percet', width: 250, slots: { customRender: 'progress_percet' } },
            ],
            param: {
                curr: 1,
                row: 10
            },
            count: 0,
            pages: 1,

        });

        const getList = () => {
            Object.assign(initData.param, { student_id: route.query.id });
            proxy.http.post('/admin.history/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        //分页change
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };

        const filterList = () => {
            initData.param.curr = 1;
            initData.list = [];
            getList();
        }

        return {
            ...toRefs(initData),
            title: '学生管理' + '【' + route.query.name + '】',
            getList,
            filterList,
            changeTableRequest,
        }
    }
})
</script>
<style lang="less" scoped>
.pageHeader {
    background-color: #f0f2f5;
    padding: 0 0 15px;
    :deep(.ant-page-header-heading-title) {
        font-weight: normal !important;
    }
}
</style>
